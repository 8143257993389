import $ from 'jquery';

export default function userBtn() {
  
  function init() {
    $('.isg-user .isg-user__button').on("click", function(e) {
      $('.isg-navigation').removeClass('open');
      $('.isg-hamburger').removeClass('open');
      $('.isg-tab__container').removeClass('open');
      $('.isg-sort').removeClass('open');
      $('.card-documents__menu').removeClass('open');

      $(this).parents('.isg-user').toggleClass('open');
      e.stopPropagation();
    });

    $(document).on("click", function(e) {
      var container = $(".isg-user");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-user .isg-user__button').length > 0) {
      init();
    }
  });
}