import $ from 'jquery';

export default function hamburger() {
  
  function init() {
    $(".isg-hamburger").on("click", function () {
      $('.js-accordion').removeClass('open');
      if(!$(this).hasClass('open')) {
        $(this).addClass('open');
        $('.isg-navigation').addClass('open');
      } else {
        $(this).removeClass('open');
        $('.isg-navigation').removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-hamburger').length > 0) {
      init();
    }
  });
  
}