import $ from 'jquery';

export default function linkCard() {
	
	function init() {
    $('.js-link-detail[data-link]').on('click', function(e) {
			const linkCard = $(this).data('link');
			const target = $(e.target);
			
			if(!target.hasClass('js-link-detail-disabled')){
				location.href = linkCard;
			}
		});
  }
  
  $( document ).ready(function() {
    if($('.js-link-detail[data-link]').length > 0) {
      init();
    }
  });
  
}