import $ from 'jquery';

export default function toggleDocumentsMenu() {
  
  function init() {
    $('.card-documents__menu .card-documents__btn').on("click", function(e) {
      $('.isg-navigation').removeClass('open');
      $('.isg-hamburger').removeClass('open');
      $('.isg-tab__container').removeClass('open');
      $('.isg-user').removeClass('open');
      $('.isg-sort').removeClass('open');

      if(!$(this).parents('.card-documents__menu').hasClass('open')) {
        $('.card-documents__menu').removeClass('open');
        $(this).parents('.card-documents__menu').addClass('open');
      } else {
        $(this).parents('.card-documents__menu').removeClass('open');
      }
      e.stopPropagation();
    });

    $(document).on("click", function(e) {
      var container = $(".card-documents__menu");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.card-documents__menu .card-documents__btn').length > 0) {
      init();
    }
  });
}