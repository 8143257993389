import $ from 'jquery';

export default function toggleFavourite() {
  
  function init() {
    $('.isg-favourite-star').on("click", function(e) {
		
		let element = $(this);
		let favouriteDocId = element.data("document-id");
		let groupId = element.data("document-group-id");
		let toggleSubscribe = element.data("toggle-subscribe");
		
		Liferay.Service(
          '/isg.myfavouritedoc/toggle-favourite',
          {
            companyId: Liferay.ThemeDisplay.getCompanyId(),
            groupId: groupId,
            userId: Liferay.ThemeDisplay.getUserId(),
			favouriteDocId: favouriteDocId,
			toggleSubscribe: toggleSubscribe
          },
          function(obj) {
            //console.log(obj);
			element.toggleClass('active'); 
          }
        );
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-favourite-star').length > 0) {
      init();
    }
  });
}