import $ from 'jquery';

export default function viewAll() {
  
  function init() {
    $('.js-viewall').on('click', function (){
      $(this).addClass('d-none').next('.js-viewless').removeClass('d-none').closest(".js-view-container").find(".isg-faccette__field").removeClass("d-none");
    });
    $('.js-viewless').on('click', function (){
      var maxElem = $(this).closest(".js-view-container").data("maxelem") || 4;
      $(this).addClass('d-none').prev('.js-viewall').removeClass('d-none').closest(".js-view-container").find(maxElem >= 0 ? ".isg-faccette__field:gt("+maxElem+")" : ".isg-faccette__field").addClass("d-none");
    });
  }
  
  $( document ).ready(function() {
    if($('.js-view-container').length > 0) {
      init();
    }
  });
}