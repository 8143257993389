import $ from 'jquery';

export default function accordion() {
  
  function init() {
    $('.isg-tab__container .isg-tab__button').on("click", function(e) {
      $('.isg-navigation').removeClass('open');
      $('.isg-hamburger').removeClass('open');
      $('.isg-user').removeClass('open');
      $('.isg-sort').removeClass('open');
      $('.card-documents__menu').removeClass('open');

      $(this).parents('.isg-tab__container').toggleClass('open');
      e.stopPropagation();
    });

    $(document).on("click", function(e) {
      var container = $(".isg-tab__container");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-tab__container .isg-tab__button').length > 0) {
      init();
    }
  });
}