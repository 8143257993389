import $ from 'jquery';

export default function toggleSortMenu() {
  
  function init() {
    $('.isg-sort .isg-sort__btn').on("click", function(e) {
      $('.isg-navigation').removeClass('open');
      $('.isg-hamburger').removeClass('open');
      $('.isg-tab__container').removeClass('open');
      $('.isg-user').removeClass('open');
      $('.card-documents__menu').removeClass('open');

      $(this).parents('.isg-sort').toggleClass('open');
      e.stopPropagation();
    });

    $(document).on("click", function(e) {
      var container = $(".isg-sort");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass('open');
      }
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-sort .isg-sort__btn').length > 0) {
      init();
    }
  });
}