import $ from 'jquery';

export default function accordion() {

  function init() {
    // clickOut
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".isg-sort").length) {
        $(".isg-sort").removeClass("open");
      }
    });
    $(document).on("click", function (event) {
      if (!$(event.target).closest(".isg-pagination__menu").length) {
        $(".isg-pagination__menu").removeClass("open");
      }
    });

    $(document).on('click', '.js-accordion__toggle', function(e) {
      $('.isg-navigation').removeClass('open');
      $('.isg-hamburger').removeClass('open');

      if(!$(this).parents('.js-accordion').hasClass('open')) {
        $(this).parents('.js-accordion').addClass('open');
      } else {
        $(this).parents('.js-accordion').removeClass('open');
      }
      e.stopPropagation();
    });
  }
  
  $( document ).ready(function() {
    if($('.js-accordion .js-accordion__toggle').length > 0) {
      init();
    
      if (process.env.LIFERAY_ENV) {  
        Liferay.on('SPAReady',function(){
          Liferay.SPA.app.on('endNavigate', function() {
            $(document).off('click', '.js-accordion__toggle');

            init();
          })
        })
      }
    }
  });  
}