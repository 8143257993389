import $ from 'jquery';

export default function tab(){
  
  function init() {
    $(".isg-tab.js-tab .isg-tab__link").on("click", function (e) {
      e.preventDefault();
      const tabGroup = $(this).parents('.isg-tab');
      const idTabSection = $(this).attr('data-id');
      
      tabGroup.find('.isg-tab__link').removeClass('isg-tab__link--active');
      $(this).addClass('isg-tab__link--active');

      tabGroup.children('.isg-tab__section').addClass('d-none');
      $('#'+idTabSection).removeClass('d-none');
    });
  }
  
  $(document).ready(function() {
    if($('.isg-tab.js-tab').length > 0) {
      init();
    }
  });
}





