import './polyfill/currentScript'
import 'core-js/stable' 
import 'regenerator-runtime/runtime'

import utils from './utils';
import hamburger from './hamburger';
import accordion from './js-accordion';
import tab from './tabs';
import lightbox from './lightbox';
import linkCard from './linkcard';
import userBtn from './isg-user';
import toggleFavourite from './isg-favourites';
import tabBtn from './isg-tab';
import toggleFilter from './isg-filter';
import toggleSortMenu from './isg-sort';
import toggleDocumentsMenu from './isg-documents';
import viewAll from './isg-showmore';
import prototipo from './prototipo';

const bundle_url = new URL(document.currentScript.src);
__webpack_public_path__ = bundle_url.pathname.substring(0, bundle_url.pathname.lastIndexOf("/")+1);

utils();
hamburger();
accordion();
tab();
lightbox();
linkCard();
userBtn();
toggleFavourite();
tabBtn();
toggleFilter();
toggleSortMenu();
toggleDocumentsMenu();
viewAll();

if (!process.env.LIFERAY_ENV) {
  prototipo();
}