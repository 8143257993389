import $ from 'jquery';

export default function toggleFilter() {
  
  function init() {
    $('.isg-faccette .isg-faccette__title').on("click", function() {

      $(this).parents('.isg-faccette').toggleClass('open');
    });
  }
  
  $( document ).ready(function() {
    if($('.isg-faccette .isg-faccette__title').length > 0) {
      init();
    }
  });
}